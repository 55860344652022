<template lang="pug">
v-menu(:close-on-content-click='false', transition='scale-transition', offset-y, v-model='menu', max-width='500')
    template(v-slot:activator='{ on, attrs }')
        v-btn.note(icon, v-on='on', color='brand', title='Add a note')
            v-icon add
    v-card(light)
        v-card-title Add Note
        v-card-text
            v-select(:items='properties', dense, outlined, color='brand', item-text='text', item-value='value', label='Column', v-model='newNoteProperty')
            v-textarea(v-model='newNoteText', label='Note Text', outlined, color='brand')
        v-card-actions
            v-spacer
            v-btn(text, color='red', @click='stopAdding') Cancel
            v-btn.white--text(color='brand', :loading='saving', @click='addNote') Add Note
</template>
<script>
export default {
    props: {
        part: {
            type: Object,
            default () {
                return {};
            }
        },
        properties: {
            type: Array,
            default () {
                return [];
            }
        }
    },
    data () {
        return {
            newNoteText: '',
            newNoteProperty: null,
            menu: false,
            saving: false
        };
    },
    methods: {
        async addNote () {
            const isCustom = this.part.hasOwnProperty('part_no');
            this.saving = true;
            await this.$store.dispatch('mmls/addNote', {
                partId: this.part.id,
                note: this.newNoteText,
                property: this.newNoteProperty,
                isCustomPart: isCustom
            });
            this.resetForm();
            this.menu = false;
            this.saving = false;
        },
        stopAdding () {
            this.resetForm();
            this.menu = false;
        },
        resetForm () {
            this.newNoteProperty = null;
            this.newNoteText = '';
        }
    },
    watch: {
        saving (isSaving) {
            if (isSaving) {
                this.$emit('saving');
            } else {
                this.$emit('doneSaving');
            }
        }
    }
};
</script>
